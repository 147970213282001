/** @jsxImportSource @emotion/react */
import { Typography } from '@vc-workspace/utils-typography';
import { downloadStoreLinkCss } from './desktop-footer.module.style';
import { VC_APP_PLAY_STORE_LINK, VC_APP_STORE_LINK } from '@vc-workspace/utils-constants';
import { sendAmplitudeData, AmplitudeEventNames } from '@vc-workspace/utils-amplitude';
type AppDownloadButtonTypes = 'ios' | 'android';
type AppDownloadFooterType = 'desktop' | 'mobile';
export const AppDownload = ({ type }: { type: AppDownloadFooterType }) => {
  return (
    <div className="flex flex-col gap-y-4">
      <Typography type={type === 'desktop' ? 'Headline_H1_22' : 'SubHeading1'} color="inherit" semi_bold>
        Experience VibeCity on Mobile
      </Typography>
      <div className="flex gap-x-6">
        <AppDownloadButton type="android" source={`${type.toUpperCase()}_FOOTER`} />
        <AppDownloadButton type="ios" source={`${type.toUpperCase()}_FOOTER`} />
      </div>
    </div>
  );
};
function trackAmplitudeEvent(type: string, source: string) {
  sendAmplitudeData(AmplitudeEventNames.DOWNLOAD_LINK_CLICKED, {
    type: type,
    source: source,
  });
}
export function AppDownloadButton({ type, source }: { type: AppDownloadButtonTypes; source: string }) {
  return (
    <div className="flex gap-x-2" css={downloadStoreLinkCss}>
      <img src={`${type === 'ios' ? '/_mp-images/app-store-logo-dark.svg' : '/_mp-images/play-store-logo-dark.svg'}`} alt="play-store" />
      <a
        href={type === 'android' ? VC_APP_PLAY_STORE_LINK : VC_APP_STORE_LINK}
        target="blank"
        rel="norefferer"
        onClick={() => trackAmplitudeEvent(type, source)}
      >
        <div className="flex flex-col cursor-pointer">
          <span style={{ fontSize: '8px' }}>Download on</span>
          <span style={{ fontSize: '16px', marginTop: '-5px' }}>{type === 'ios' ? 'App Store' : 'Google Play'}</span>
        </div>
      </a>
    </div>
  );
}
