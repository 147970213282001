/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';

import Image from 'next/image';
import { Typography } from '@vc-workspace/utils-typography';
import { FooterConstants } from '@vc-workspace/utils-constants';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { AppDownload } from '@vc-workspace/desktop-footer';

import { addressStyles, containerStyles, marginBottom12, socialMediaContainerStyles, imageStyles } from './mobile-footer.module.style';

const PaymentMethodImage = styled(Image)`
  margin-right: 8px !important;
`;

interface MobileFooterProps {
  ignoreDefaultMargin?: boolean;
  appendDomain?: boolean;
}

export function MobileFooter(props: MobileFooterProps) {
  const onClick = (ev: any) => {
    ev.preventDefault();
    window.open(FooterConstants.sell_on_vibecity_url, '_blank');
  };

  const pathPrefix = props.appendDomain ? 'https://www.vibecity.com' : '';

  return (
    <div css={containerStyles} className={!props.ignoreDefaultMargin ? 'mt-12' : ''}>
      <div className="page-cushion-container">
        <div className="mb-6">
          <AppDownload type="mobile" />
        </div>
        <div className="pure-g">
          <div className="pure-u-1-2">
            <Typography type={'SubHeading1'} color="grape" custom_style={[marginBottom12]} semi_bold>
              About
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={[marginBottom12]}>
              <a href={pathPrefix + FooterConstants.about_us_url}>About Us</a>
            </Typography>
            {!pathPrefix ? (
              <a href={pathPrefix + FooterConstants.sell_on_vibecity_url} onClick={onClick}>
                <Typography color="dim_grey" type="Body1" custom_style={[marginBottom12]}>
                  Sell on Vibecity
                </Typography>
              </a>
            ) : null}
          </div>
          <div className="pure-u-1-2">
            <Typography type={'SubHeading1'} color="grape" custom_style={[marginBottom12]} semi_bold>
              Follow Us
            </Typography>
            <div>
              {FooterConstants.instagram_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.instagram_url} target="_blank" rel="noreferrer">
                    <InstagramIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
              {FooterConstants.facebook_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.facebook_url} target="_blank" rel="noreferrer">
                    <FacebookIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
              {FooterConstants.youtube_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.youtube_url} target="_blank" rel="noreferrer">
                    <YouTubeIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
              {FooterConstants.twitter_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.twitter_url} target="_blank" rel="noreferrer">
                    <TwitterIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
            </div>
          </div>
        </div>
        <div className="pure-g pt-12">
          <div className="pure-u-1-2">
            <Typography type="SubHeading1" color="grape" custom_style={marginBottom12} semi_bold>
              Policies
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
              <a href={pathPrefix + FooterConstants.privacy_policy_url}>Privacy Policy</a>
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
              <a href={pathPrefix + FooterConstants.terms_conditions_url}>Terms and Conditions</a>
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
              <a href={FooterConstants.return_policy_url}>Return Policy</a>
            </Typography>
          </div>
          <div className="pure-u-1-2">
            <Typography type="SubHeading1" color="grape" custom_style={marginBottom12} semi_bold>
              Contact Us
            </Typography>
            {!pathPrefix ? (
              <Typography color="dim_grey" type="Body1" custom_style={marginBottom12}>
                <address css={addressStyles}>{FooterConstants.address}</address>
              </Typography>
            ) : null}
          </div>
        </div>
        <div className="pt-12">
          <Typography type="SubHeading1" color="grape" custom_style={[marginBottom12]} semi_bold>
            Payment Supported
          </Typography>
          <div css={[marginBottom12]}>
            <img src={`${pathPrefix}/_mp-images/visa.png`} alt="" css={imageStyles} className="lazyload" />
            <img src={`${pathPrefix}/_mp-images/upi.png`} alt="" css={imageStyles} className="lazyload" />
            <img src={`${pathPrefix}/_mp-images/rupay.png`} alt="" css={imageStyles} className="lazyload" />
            <img src={`${pathPrefix}/_mp-images/mastercard.png`} alt="" css={imageStyles} className="lazyload" />
            <img src={`${pathPrefix}/_mp-images/maestro.png`} alt="" css={imageStyles} className="lazyload" />
            <img src={`${pathPrefix}/_mp-images/netbanking.png`} alt="" css={imageStyles} className="lazyload" />
            <img src={`${pathPrefix}/_mp-images/cod.png`} alt="" css={imageStyles} className="lazyload" />
          </div>
        </div>
        <Typography type="Body1" color="silver" className="pt-8 pb-4">
          &copy; 2022 VibeCity. All rights reserved
        </Typography>
      </div>
    </div>
  );
}
