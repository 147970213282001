/** @jsxImportSource @emotion/react */

import { Typography } from '@vc-workspace/utils-typography';
import { FooterConstants, SourceKey } from '@vc-workspace/utils-constants';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {
  containerStyles,
  addressStyles,
  dottedSeparatorStyles,
  gridSection,
  marginBottom12,
  marginBottom24,
  socialMediaContainerStyles,
  solidSeparatorStyles,
  textAlignCenter,
  imageStyles,
  scrollToTop,
} from './desktop-footer.module.style';
import { AppDownload } from './app-download';

interface DesktopFooterProps {
  ignoreDefaultMargin?: boolean;
  appendDomain?: boolean;
}

export function DesktopFooter(props: DesktopFooterProps) {
  const pathPrefix = props.appendDomain ? 'https://www.vibecity.com' : '';

  function onScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div css={containerStyles} className={!props.ignoreDefaultMargin ? 'mt-32' : ''}>
      <div className="page-cushion-container">
        <div className="pure-g relative">
          <div className="pure-u-6-24">
            <AppDownload type="desktop" />
          </div>
          <div className="pure-u-3-24">
            <Typography type="SubHeading1" color="grape" custom_style={marginBottom12} semi_bold>
              About
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
              <a href={pathPrefix + FooterConstants.about_us_url}>About Us</a>
            </Typography>
            {!pathPrefix ? (
              <a href={`${pathPrefix}${FooterConstants.sell_on_vibecity_url}?${SourceKey}=Footer`}>
                <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
                  Sell on Vibecity
                </Typography>
              </a>
            ) : null}
          </div>
          <div className="pure-u-3-24">
            <Typography type="SubHeading1" color="grape" custom_style={marginBottom12} semi_bold>
              Policies
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
              <a href={pathPrefix + FooterConstants.privacy_policy_url}>Privacy Policy</a>
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
              <a href={pathPrefix + FooterConstants.terms_conditions_url}>Terms and Conditions</a>
            </Typography>
            <Typography color="dim_grey" type="Body1" custom_style={marginBottom12} hover_color="nero">
              <a href={FooterConstants.return_policy_url}>Return Policy</a>
            </Typography>
          </div>
          <div className="pure-u-3-24">
            <Typography type="SubHeading1" color="grape" custom_style={marginBottom12} semi_bold>
              Contact Us
            </Typography>
            {!pathPrefix ? (
              <Typography color="dim_grey" type="Body1" custom_style={marginBottom12}>
                <address css={addressStyles}>{FooterConstants.address}</address>
              </Typography>
            ) : null}
          </div>
          <div className="pure-u-8-24 pl-16">
            <Typography type="SubHeading1" color="grape" custom_style={[marginBottom12]} semi_bold>
              Follow Us
            </Typography>
            <div css={marginBottom24}>
              {FooterConstants.instagram_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.instagram_url} target="_blank" rel="noreferrer">
                    <InstagramIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
              {FooterConstants.facebook_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.facebook_url} target="_blank" rel="noreferrer">
                    <FacebookIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
              {FooterConstants.youtube_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.youtube_url} target="_blank" rel="noreferrer">
                    <YouTubeIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
              {FooterConstants.twitter_url ? (
                <Typography color="heavy_metal" type="Caption" custom_style={socialMediaContainerStyles}>
                  <a href={FooterConstants.twitter_url} target="_blank" rel="noreferrer">
                    <TwitterIcon fontSize="small" />
                  </a>
                </Typography>
              ) : null}
            </div>
            <Typography type="SubHeading1" color="grape" custom_style={[marginBottom12]} semi_bold>
              Payment Supported
            </Typography>
            <div css={[marginBottom12]} className="flex">
              <img src={`${pathPrefix}/_mp-images/visa.png`} alt="" css={imageStyles} className="lazyload" />
              <img src={`${pathPrefix}/_mp-images/upi.png`} alt="" css={imageStyles} className="lazyload" />
              <img src={`${pathPrefix}/_mp-images/rupay.png`} alt="" css={imageStyles} className="lazyload" />
              <img src={`${pathPrefix}/_mp-images/mastercard.png`} alt="" css={imageStyles} className="lazyload" />
              <img src={`${pathPrefix}/_mp-images/maestro.png`} alt="" css={imageStyles} className="lazyload" />
              <img src={`${pathPrefix}/_mp-images/netbanking.png`} alt="" css={imageStyles} className="lazyload" />
              <img src={`${pathPrefix}/_mp-images/cod.png`} alt="" css={imageStyles} className="lazyload" />
            </div>
          </div>
          <img src={`${pathPrefix}/_mp-images/scroll_to_top.png`} css={scrollToTop} alt="Scroll to top" onClick={onScrollToTop} />
        </div>
        <Typography type="Body1" color="silver" className="pb-4">
          &copy; 2022 VibeCity. All rights reserved
        </Typography>
      </div>
    </div>
  );
}
