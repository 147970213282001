import { css } from '@emotion/react';
import {DIMENSIONS} from '@vc-workspace/utils-dimens'
import {Colors} from '@vc-workspace/utils-colors'

export const containerStyles = css({
  background: Colors.white_smoke,
  paddingTop: DIMENSIONS.px_40,
  paddingBottom: DIMENSIONS.px_20
});

export const marginBottom12 = css({
  marginBottom: DIMENSIONS.px_12
});

export const addressStyles = css({
  fontStyle: 'normal'
});

export const socialMediaContainerStyles = css({
  display: 'inline-block',
  background: Colors.dusty_gray_10,
  padding: DIMENSIONS.px_8,
  borderRadius: DIMENSIONS.px_12,
  marginRight: DIMENSIONS.px_16,
  marginBottom: DIMENSIONS.px_16
});

export const imageStyles = css({
  display: 'inline-block',
  width: DIMENSIONS.px_60,
  marginRight: DIMENSIONS.px_8,
  marginBottom: DIMENSIONS.px_8
})
