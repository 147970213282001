import { css } from '@emotion/react';
import { DIMENSIONS } from '@vc-workspace/utils-dimens';
import { Colors } from '@vc-workspace/utils-colors';

export const containerStyles = css({
  background: Colors.white_smoke,
  paddingTop: DIMENSIONS.px_80,
  paddingBottom: DIMENSIONS.px_40,
});

export const textAlignCenter = css({
  textAlign: 'center',
});

export const marginBottom24 = css({
  marginBottom: DIMENSIONS.px_24,
});

export const marginBottom12 = css({
  marginBottom: DIMENSIONS.px_12,
});

export const dottedSeparatorStyles = css({
  marginTop: DIMENSIONS.px_24,
  marginBottom: DIMENSIONS.px_24,
  borderBottom: `1px dashed ${Colors.dim_grey}`,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '250px',
});

export const solidSeparatorStyles = css({
  marginTop: DIMENSIONS.px_20,
  marginBottom: DIMENSIONS.px_20,
  borderBottom: `1px solid ${Colors.dim_grey}`,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '250px',
});

export const verticalAlignMiddle = css({
  verticalAlign: 'middle',
});

export const addressStyles = css({
  display: 'inline-block',
  fontStyle: 'normal',
  width: '235px',
});

export const gridSection = css({
  paddingLeft: DIMENSIONS.px_12,
  paddingRight: DIMENSIONS.px_12,
  textAlign: 'center',
});

export const socialMediaContainerStyles = css({
  display: 'inline-block',
  background: Colors.dusty_gray_10,
  padding: DIMENSIONS.px_8,
  borderRadius: DIMENSIONS.px_12,
  marginRight: DIMENSIONS.px_20,
  '&:hover': {
    cusrsor: 'pointer',
  },
});

export const imageStyles = css({
  display: 'inline-block',
  width: DIMENSIONS.px_60,
  marginRight: DIMENSIONS.px_8,
});

export const scrollToTop = css({
  position: 'absolute',
  top: '0px',
  right: '0px',
  width: DIMENSIONS.px_24,
  '&:hover': {
    cursor: 'pointer',
  },
});
export const downloadStoreLinkCss = css({
  border: `1px solid ${Colors.east_bay}`,
  borderRadius: '8px',
  padding: '0.25rem 0.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  background: '#171717',
  color: 'white',
  cursor: 'pointer',
  transition: 'box-shadow 0.4s ease-in-out',
  img: {
    width: '25px',
    height: '25px',
  },
  '&:hover': {
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
  },
});
